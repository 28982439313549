import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/authentication/authentication.module').then((m) => m.AuthenticationModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./modules/user-management/user-management.module').then((m) => m.UserManagementModule)
  },
  {
    path: 'team',
    loadChildren: () => import('./modules/team-management/team-management.module').then((m) => m.TeamManagementModule)
  },
  {
    path: 'badge',
    loadChildren: () => import('./modules/batch-management/batch-management.module').then((m) => m.BatchManagementModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule)
  },
  {
    path: 'transaction',
    loadChildren: () => import('./modules/transaction/transaction.module').then((m) => m.TransactionModule)
  },
   
   
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
