<div class="header">
    <div class="title">
        <div class="headname">
            <!-- <span *ngIf="backHandler" (click)="goBackButton()">
                <img src="assets/images/arrow-green.svg" />
            </span> -->
            {{pageDetails.name}}
        </div>   
        <!-- <div class="header_licence_button" *ngIf="currentRouter == '/corporate-licensing'">
            <button class="btn btn-success pull-right" (click)="addLicence()"> Add Organisation</button>
            <button class="btn btn-success pull-right" (click)="importCsv()" style="min-width: auto;">Import</button> 
        </div> -->
         
        <!-- <div class="header_licence_button" *ngIf="currentRouter == '/payment'">
            <button class="btn btn-success pull-right" (click)="addPayment()"> + New payment</button>
        </div> -->
    </div>
    
    <div class="logout-btn" (click)="logout()">Logout</div>
</div> 