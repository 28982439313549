<div class="side-menu">
    <div class="logo"></div>
    <ul class="menu">
        <a *ngFor="let menu of menuList; let i=index" title="{{menu.title}}" routerLink="{{menu.path}}" [ngClass]="rla.isActive ? 'activeTab' : ''" routerLinkActive #rla="routerLinkActive" >
            <!-- <li [ngClass]="rla.isActive ? 'activeTab' : ''"></li> -->
            <li></li>
        </a>
    </ul>
    <a *ngIf="common.RoleName =='Y'" class="setting" routerLink="/settings" [ngClass]="rla.isActive ? 'activeTab' : ''" routerLinkActive #rla="routerLinkActive" >
    <div  >
        <!-- <a  title="Setings"  > -->
         
    </div></a>
</div>