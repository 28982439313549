import { Injectable } from '@angular/core';
import { BehaviorSubject,Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataServiceService {
  csv=0
  public userEmails: any = [];
  public importCsv: number=0;
  constructor() { }
  public subject = new Subject<any>();
  private messageSource = new  BehaviorSubject(this.userEmails);
  private csvStatus = new BehaviorSubject(this.importCsv)
  currentEmails = this.messageSource.asObservable();
  currentCsvStatus = this.csvStatus.asObservable()

  updateEmails(email) {
    this.messageSource.next(email)
  }
  openCsv(status){
    this.csvStatus.next(status)
  }
}
