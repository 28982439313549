import { Component, OnInit, Input,Output,EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataServiceService } from '../../../shared/service/data-service.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../../services/common.service';
import { Router,ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';



@Component({ 
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() pageDetails={
    name:'',
  };
  currentRouter: string;
  backHandler = false;
  csv=1
  token;

  constructor(
    private common: CommonService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private location: Location,
    private dataService: DataServiceService
  ) {
    router.events.subscribe(val => {
      this.backHandler = this._checkCurrentRoute(location['_platformLocation'].location.pathname);
    });
    router.events.subscribe((url:any) => this.currentRouter=url.url);
  }

  // tslint:disable-next-line: typedef
  _checkCurrentRoute(key) {
    let label = true;
    switch (key) {
      case '/user':
        label = false;
        break;
      case '/eqme-frontend/user':
        label = false;
        break;
    }
    return label;
  }

  ngOnInit(): void {
    
    this.token = localStorage.getItem('token');
    if (!this.token) {
      this.clear();
    }
  }

  goBackButton(): void {
    this.location.back();
  }

  clear(): void {
    localStorage.clear();
    this.router.navigate(['']);
  }

  logout(): void {
    this.spinner.show();
    this.common.logout().subscribe((response) => {
      this.router.navigate(['']);
      this.toastr.success('Logged out successfully', '');
      this.token = '';
      localStorage.clear();
      this.spinner.hide();
    },
      err => {
        this.toastr.error(err.error.message, '');
        this.spinner.hide();
      });
  }
   
}
