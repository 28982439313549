import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { FormErrorComponent } from './form-error/form-error.component';
import { InputNumberDirective }from './service/input-number.directive'
import {OnlyNumbersDirective} from './service/only-numbers.directive';
import { MomentModule } from 'ngx-moment';

@NgModule({
  imports: [CommonModule, MomentModule],
  declarations: [InputNumberDirective,OnlyNumbersDirective],
  exports: [InputNumberDirective,OnlyNumbersDirective]
})
export class SharedModule { }
