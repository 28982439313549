import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, retry } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';



@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private route: Router,
        private spinner: NgxSpinnerService,
        private toastr: ToastrService,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(retry(1), catchError(err => {
            if (err.status !== 422) {
                this.spinner.hide();
                this.toastr.error(err.error.message, '');
                if (err.status === 401) {
                    localStorage.clear();
                    this.route.navigate(['/']);
                }
            } else {
                this.spinner.hide();
                this.toastr.error(err.error.errors[0].msg, '');
            }
            const error = err.error.message || err.statusText;
            return throwError(error);
        }));
    }
}
