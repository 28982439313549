import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute  } from '@angular/router';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
  selectedTab:number=0
  menuList = [{ menuName: '', path: '/home'},
    { menuName: 'user-management',title: 'User Management', path: '/user' }, 
    { menuName: 'transactions',title: 'Transactions', path: '/transaction' },
    { menuName: 'team-management',title: 'Team Management', path: '/team' },
    { menuName: 'batch-management',title: 'Badge Management', path: '/badge' }
   
    
  ];
  constructor(
    public router: Router,
    private activatedroute:ActivatedRoute ,
    public common:CommonService
  ) { }

  ngOnInit(): void {
    let Role = localStorage.getItem('Role');
    if(Role == 'ADMIN' || Role == 'KYC_PROCESSOR_USER_ADMIN'){
      this.common.RoleName = 'Y'
    }else if(Role== 'KYC_PROCESSOR'){
      this.common.RoleName = 'N'
    }
  }

  routeDirect(path,i): void {
    this.router.navigate([path]);
    this.selectedTab=i;
  }

}
