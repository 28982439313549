import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class CommonService {
    RoleName:any ='Y';
    constructor(private http: HttpClient) { }
    logout(): Observable<any> {
        return this.http.get(`${environment.api}admin/logout`, {});
    }
    listUsers(data): Observable<any> {
        return this.http.get(`${environment.api}admin/userList?` + data);
    }
    changePassword(data): Observable<any> {

        return this.http.post(`${environment.api}admin/changePassword`, data);
    }
    kycApprove(data): Observable<any> {

        return this.http.post(`${environment.api}admin/kycUpdate`, data);
    }
    blockUser(data): Observable<any> { 
        return this.http.put(`${environment.api}admin/block`, data);
    }
    unblockUser(data): Observable<any> {

        return this.http.put(`${environment.api}admin/unblock`, data);
    }
    getUserByID(userID): Observable<any> {
        return this.http.get(`${environment.api}admin/user?userId=`+userID);
    }
    userTransactionHistory(data): Observable<any> {
        return this.http.get(`${environment.api}admin/userTransactionHistory?` + data);
    }
    getAllTransactions(data): Observable<any>{
        return this.http.get(`${environment.api}admin/transactions?`+ data);
    }
    // downloadSvg():Observable<any>{
    //     return this.http.get(`${environment.api}admin/download?`);
    // }
    downloadCsv(data):Observable<any>{
        let apiUrl= this.http.get(`${environment.api}admin/download?`+ data);
        apiUrl.subscribe(res=>{
        console.log(res,"downldapi")
        })
        return apiUrl
    }

    AddTeamMember(data):Observable<any>{ 
        return this.http.post(`${environment.api}admin/teamMember`, data)
    }

    UpdateTeamMember(data,id):Observable<any>{
        return this.http.put(`${environment.api}admin/teamMember/${id}`, data)
    }

    deleteTeamMember(data,id):Observable<any>{
        return this.http.delete(`${environment.api}admin/teamMember/${id}`, data)
    }

    getTeamMemberDetails(InputText):Observable<any>{
        return this.http.get(`${environment.api}admin/fetchTeamMemberByEmail?email=`+InputText)
    }
     
    AssignTomySelf(data):Observable<any>{
        return this.http.post(`${environment.api}admin/assignYourself`, data)
    }

    getAllMembers(data){
        // http://localhost:8080/admin/fetchAllTeamMembers?searchString=&page=1&limit=10
        let url = `${environment.api}/admin/fetchAllTeamMembers?page=${data.page}&limit=${data.limit}`;
        if(data.searchtext){
            url += `&searchString=${data.searchtext}`
        }
        return this.http.get(url)
    }

    badgeConfigSave(data){
        return this.http.post(`${environment.api}admin/badge`, data)
    }

    badgeSave(data){
        return this.http.post(`${environment.api}admin/badgeForUser`, data)
    }

    getBadgeDetails(Type){
        return this.http.get(`${environment.api}admin/badge/${Type}/type`)
    }

    getUserList(searchText:any){
        return this.http.get(`${environment.api}admin/fetchUsers?searchString=${searchText}`)
    }
    cardPurchasesBlockUnblock(data): Observable<any> { 
        return this.http.put(`${environment.api}admin/cardPurchase`, data);
    }
}
